.duration-cell {
  width: 30%;
}

#price-table > tbody > tr > td {
  vertical-align: top !important;
}

.button-add-service {
  white-space: nowrap;
  font-size: 11px !important;
}

.hidden-input-value {
  .form-control {
    color: #e9ecef !important;
    cursor: not-allowed;
  }
}
.duration-wrapper {
  gap: 0.625rem;
}
